import "./Sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LogoutIcon from '@mui/icons-material/Logout';
import axios from "axios";
import SettingsIcon from '@mui/icons-material/Settings';
import { Button } from "@mui/material";
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import * as React from 'react';
import { styled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { Link, useParams } from "react-router-dom";

import { useContext } from "react";

const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS;

const storageLimit = 1 * 1024 * 1024 * 1024; // 1 GB in bytes

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const handleLogout = async () => {
    await axios({
      method: "POST",
      url: `${backEndUrl}/auth/logout`, 
      withCredentials: true,
    }).then((response) => {
      // redirect to /
      window.location.href = '/';
    });
    // Perform any additional client-side logout actions if necessary
};

const Sidebar = (props) => {
  const percentageUsed = (props.storageUsed / storageLimit) * 100;
  const { id } = useParams();
  
  return (
    <div className="sidebar">
      <div className="top">
          <span className="logo">DocUp</span>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">Home</p>
            <Link to={"/homepage/" + props.userID} style={{ textDecoration: "none" }}>
          <li>
            <AccountBoxIcon className="icon" />
              <span>Clients</span>
          </li>
            </Link>
          <p className="title">List</p>

          <Link to={"template-list"} style={{ textDecoration: "none" }}>
            <li>
              <FormatListNumberedIcon className="icon" />
              <span>Template</span>
            </li>
          </Link>
        
          <p className="title">Create</p>
          <Link to={"newClient/" + props.userID} style={{ textDecoration: "none" }}> 
            <li>
              <CreateNewFolderIcon className="icon" />
              <span>Client</span>
            </li>
          </Link>
          <Link to={"docTemplate"} style={{ textDecoration: "none" }}>
            <li>
              <LibraryBooksIcon className="icon" />
              <span>Template</span>
            </li>
          </Link>

          <p className="title">Help</p>
          <Link to={"settings/" + id} style={{ textDecoration: "none" }}>
            <li>
              <SettingsIcon className="icon" />
              <span>Settings</span>
            </li>
          </Link>

            <Link to={"support/" + id} style={{ textDecoration: "none" }}>
          <li>
            <SupportAgentOutlinedIcon className="icon" />
              <span>Support</span>
          </li>
            </Link>
          <li>
            <LogoutIcon className="icon" />
            <span onClick={handleLogout}>Logout</span>
          </li>
          <li></li>

          <Stack spacing={2} style={{ marginTop: '100%' }} sx={{ flexGrow: 1 }}>
            <br />
            <p style={{ fontSize: '13px', marginLeft: '8%' }}>Storage used: {Math.round(percentageUsed)} %</p>
            {percentageUsed < 100 ? 
              <BorderLinearProgress style={{ width: '80%', marginLeft: '7%' }} variant="determinate" value={percentageUsed} /> : 
              <p style={{ fontSize: '13px', color: 'red' }}>Warning: storage limit reached, please upgrade your account</p>
            }
          </Stack>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;