import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import Axios from "axios";
// import { userColumns, userRows } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useState } from "react";
import MUIDataTable from "mui-datatables";

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { GridToolbarFilterButton} from "@mui/x-data-grid";

const TemplateTable = (props) => {

  const [open, setOpen] = React.useState(false);
  const [templateID, setTemplateID] = React.useState();

  const handleClickOpen = (templateID) => {
    setTemplateID(templateID);
    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
  };
 
  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

    const [data, setData] = useState();
    const [docTemplates, setDocTemplates] = useState([]);

    useEffect(() => {
        Axios ({
           method: "GET",
          withCredentials: true,
            url: `${backEndUrl}/auth/consultantLog`,
     
         
          }).then((res) => {
           setData(res.data);       
           if (res.data && res.data.docTemplates) {
            setDocTemplates(res.data.docTemplates);
          } else {
            console.error('docTemplates data not found:', res.data);
          }
          });
     }, []) 

     const handleDelete = async (id) => {

      const templateToDelete = docTemplates.find(template => template._id === id);
      const documentsArray = templateToDelete ? templateToDelete.documents : [];
      
  

      try {
        await Axios({
          method: 'DELETE',
          withCredentials: true,
          data: {
             // doc_id: id,
              template_id: id,
              documents: documentsArray,
              storageUsed : data.storageUsed,
              // here i want all the documents array of the specific template 
             
            
          },
          url: `${backEndUrl}/updateClient/deleteTemplate`,
        });
        
       window.location.reload();
      } catch (error) {
        
      }
      
    };  



  

  const columns2 = [
  
    { field: "client_name", headerName: "Name", width: 780 ,
    valueGetter: (params) =>
      `${params.row.name || ""} `,
  },

   




   
  ]



  // actionColumn is used here to add the action buttons
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={'viewTemplate/'+ params.row._id} style={{ textDecoration: "none" }}>
    
               <Button variant='outlined' size="small">Modify</Button>
            </Link>
            <Button variant='outlined' size="small"  color="error" onClick={() => handleClickOpen(params.row._id)}>Delete</Button>


            
          </div>
        );
      },
    },
  ];

  if (data === undefined) {

    return (<>Still loading...</>)
}

else {
// handleClickOpen triggger

  return (
    
    <div className="datatable">
 
 <React.Fragment>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this template ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this template? This will also delete all associated documents. Warning: This action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' size='small' onClick={handleClose}>Cancel</Button>
          <Button  variant='contained' size='small' onClick={() => handleDelete(templateID)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

      <DataGrid
      
        
        rows={props.clients}
        columns={columns2.concat(actionColumn)}
        getRowId={(row) => row._id}
     
        localeText={{
 
 toolbarFilters: "Search",

}}
     components={{
 Toolbar: GridToolbarFilterButton ,
}}


      

       
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
       
      />
    </div>
  );
};
};

export default TemplateTable;