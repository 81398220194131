import "./TableDoc.scss";
import Axios from "axios";
import * as React from 'react';
import { DataGrid, GridDeleteIcon, GridToolbarFilterButton} from "@mui/x-data-grid";
// import { userColumns, userRows } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useState , useEffect} from "react";
import MUIDataTable from "mui-datatables";
import { GridToolbar } from "@mui/x-data-grid";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; 

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';

import { IconButton, CircularProgress } from '@mui/material';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { set } from "mongoose";


import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const options = [
  
  'Download',
  /* 'Correct', */
  'Delete',
  'See your reference',
];

const ITEM_HEIGHT = 48;

const TableDoc = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDocId, setSelectedDocId] = useState(null); // this contains the WHOLE params object of your row 

  const openMenu = Boolean(anchorEl);
  
  const handleClick = (event, docId) => {
    setAnchorEl(event.currentTarget);
    setSelectedDocId(docId);
    
  };

  const handleCloseMenu = (option, docID) => {


   
    setAnchorEl(null);
    // Call the appropriate function based on the option
     if (option) {
      handleOptionClick(option, docID);
     
    } 
  };

  const handleOptionClick = (option, params) => {

    
    switch (option) {
     
      case 'See your reference':
        preview(params.doc_upload_consultantSide);
        break;
      case 'Download':
        handleDownloader(params.doc_upload);
        break;
      case 'Correct':
       
       handleClickOpen(params.name, params.description)()
        break;
      case 'Delete':
        
        handleClickOpenDelete(params)
        break;
   
      default:
        
    }
  };

  

  const preview = async (params) => {

    if (params === 'none') {
      alert('You didn’t attach any reference file for this document. To view your client’s file upload instead, click the button under the "Preview" column.');
      return;
    }
    
    try {
      const response = await fetch(`${backEndUrl}/updateClient/viewDoc`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // credentials very important here, what makes req.authenticated work in the backend
        credentials: 'include',
        body: JSON.stringify({ key: params }), // Replace 'your_key_here' with the appropriate key
      });
      const data = await response.json();
  
      const newWindow = window.open();
      newWindow.location.href = data;
      // Do something with the pre-signed URL
    } catch (error) {
      console.error('Error fetching pre-signed URL: ', error);
    }
  };





  // 

  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

 

 const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

 const [previewUrl, setPreviewUrl] = useState('');
 const [docSize, setDocSize] = useState('');
 const [docID, setDocID] = useState('');
 const [docPath, setDocPath] = useState('');



 dayjs.extend(utc); 
 const [dateDeadline, setdateDeadline] = useState(dayjs.utc(props.deadline));

 const handleDateChange = (newDate) => {
  setdateDeadline(newDate);
};

//---------------------------------------------

const [openDelete, setOpenDelete] = React.useState(false);

const [warningDate, setWarningDate] = React.useState(false);

const handleClickOpenDelete = (document) => {
 

  setDocPath(document.doc_upload);
  setDocID(document._id)
  setDocSize(document.size);
  setOpenDelete(true);
};

const handleCloseDelete = () => {
  setOpenDelete(false);
};


//---------------------------------------------
 const [open, setOpen] = React.useState(false);

 const [docName, setDocName] = useState('');


 const [docDescription, setDocDescription] = useState('');

 const [nameState, setNameState] = useState(false);
 const [descriptionState, setDescriptionState] = useState(false);

 const [nameCorrected, setNameCorrected] = useState('');
 const [descriptionCorrected, setDescriptionCorrected] = useState('');


 const CustomColumn = ({ rowData }) => {
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const handleIconClick = async () => {
    setLoading(true);
    // Need document id and client id to update the status of the document

    try {
      
      await Axios ({
          method: "PUT",
          withCredentials: true,
          data: {
            client_id: props.ridi,
            document_id: rowData._id,
            review: rowData.review,
          },
          
           url : `${backEndUrl}/updateClient/setReview/` 
        
      })
      setLoading(false);
      setCompleted(prevCompleted => !prevCompleted);
      window.location.reload();
    } catch (error) {
      
    }
   

  };

  return (
    <div style={{ textAlign: 'center' }}>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        rowData.doc_upload !== '' && ( 
        <IconButton onClick={handleIconClick} disabled={loading}>
          {completed || rowData.review === 'good' ? <CheckCircleOutlineIcon style={{ color: 'green' }} /> :  <AddCircleRoundedIcon />}
        </IconButton>
        )
      )}
    </div>
  );
};
 
  







 const changeDocName = (event)=> {
  
  event.preventDefault();
  setNameState(true);
  setDocName(event.target.value);
};


const changeDocDescription = (event) => {
  event.preventDefault();
  setDescriptionState(true);
  setDocDescription(event.target.value);
 
};

  const handleClickOpen = (name, description) => () =>{

    
    const currentDate = dayjs.utc();

    if (currentDate.isAfter(dayjs.utc(props.deadline))) {
      setWarningDate(true);
    } else {
      setWarningDate(false);
    }

    setDocName(name);
    setDocDescription(description);
    setOpen(true);
  };

  const handleClose = () => {
    setNameState(false)
    setDescriptionState(false)
    setOpen(false);
  };


const sendForm = () => {


  axios.post(`${backEndUrl}/updateClient/addDoc`, {
    doc_name: docName,
    doc_description: docDescription,
    client_id: props.ridi,
    deadline: dateDeadline,
  })
  .then(response => {
    
    window.location.reload();
  })
  .catch(error => {
    console.error("Error:", error);
    // Handle the error here
  });
}




  //---------------------------------------------



function handlePreview(fileUrl) {
 
  setPreviewUrl(fileUrl);
  window.open(encodeURI(fileUrl), '_blank');
}

function handleDownload(fileUrl) {

  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
  link.click();
}



// function to pull the id of the document to be deleted
    const handleDelete = async (id) => {
   
    try {
      await Axios({
        method: 'DELETE',
        withCredentials: true,
        data: {
            doc_id: docID,
            client_id: props.ridi,
           storageUsedByConsultant: props.storageUsed,
            docSize: docSize,
            path: docPath,

          
        },
        // url: `${backEndUrl}/updateClient/deleteDocument`,
        url: `${backEndUrl}/updateClient/deleteSpecificDocument`,
      });
      
     window.location.reload();
    } catch (error) {
      
      
    }
    
  };  

  const handleDownloader = (key) => {
    window.location.href = `${backEndUrl}/updateClient/download/${key}`; // Change this URL according to your backend setup
  };


 
  


  const columns2 = [

   
  
    { field: "client_name", headerName: "Documents", width: 250 ,  
    valueGetter: (params) =>
      `${params.row.name || ""} ${params.row.client_lastName || ""}`,
  },








  // Your existing columns...
  {
    field: 'customColumn',
    headerName: 'Validation',
    width: 100,
    headerClassName: 'bold-header',
    renderCell: (params) => <CustomColumn rowData={params.row} />,
  },

  {
    field: 'description',
    headerName: 'Description',
    width: 100,
    headerClassName: 'bold-header',
    renderCell: (params) => {
      return (
        <div className="cellAction">
        <div>
<HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit" fontSize={18} >Description</Typography>
            <div style={{ maxWidth: 300 }}>
            <pre style={{fontSize: 15, fontFamily: 'Helvetica', whiteSpace: 'pre-wrap'}}>
            {params.row.description}
            </pre>
           </div>
          </React.Fragment>
        }
      >
              <DescriptionIcon style={{ color: 'orange' }}/>
      </HtmlTooltip>
    </div>
    </div>
      );
    }
  },


 



    { field: "program", headerName: "Availability", headerClassName: 'bold-green-header', width: 140 ,
    cellClassName: (params) =>{ 
  const availability = params.value.toLowerCase();
  if (availability === "uploaded") {
    return "uploaded";
  } else if (availability === "not uploaded") {
    return "notUploaded";
  } 
  return ""; // Default CSS class if the status is not recognized
},
valueGetter: (params) =>
`${params.row.status || ""}`,
},

{
  field: "aperçu",
  headerName: "Preview",
  width: 100,
  renderCell: (params) => {
    return (
      <div style={{ textAlign: "center", marginLeft:'4%' }}>
      { params.row.status !=='not uploaded' ?
      <VisibilityIcon  variant='outlined' size='small' color="success" onClick={async () => {
try {
const response = await fetch(`${backEndUrl}/updateClient/viewDoc`, {
method: 'POST',
headers: {
  'Content-Type': 'application/json',
},
// credentials very important here, what makes req.authenticated work in the backend
credentials: 'include',
body: JSON.stringify({ key: params.row.doc_upload }), // Replace 'your_key_here' with the appropriate key
});
const data = await response.json();
const queryParams = new URLSearchParams({
            url: data,
            docName: params.row.name,
            docDescription: params.row.description,
            userId: props.ridi, 
            docID : params.row._id,
            deadline: props.deadline
        }).toString();
const viewDocumentUrl = `/view-document?${queryParams}`;
window.open(viewDocumentUrl, '_blank');

// const newWindow = window.open();
// newWindow.location.href = data;

// Do something with the pre-signed URL
} catch (error) {
console.error('Error fetching pre-signed URL: ', error);
}
}}
style={{ marginLeft: "30%" , cursor:'pointer'}}/> : null

    }
    </div>
    );
  },
},


{ field: "date", headerName: "Submission date", width: 190 ,    renderCell: (params) => {
  
  if (!params.row.date) {
    return "";
  }
  const date = new Date(params.row.date);
  return (
    
  
    <div>
     {date.toDateString()}
  
    </div>
   


      
  );
}

},



     
      ,

      

      {
        field: 'action',
        headerName: 'Action',
        width: 100,
        headerClassName: 'bold-header',
        renderCell: (params) => {
          return (
            <div className="cellAction">
       <div>
    
    
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={openMenu ? 'long-menu' : undefined}
            aria-expanded={openMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={(event) => handleClick(event, params.row)}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={openMenu}
              onClose={() => handleCloseMenu(selectedDocId)}
    
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            {options.map((option) => (
              <MenuItem key={option}  onClick={() => handleCloseMenu(option, selectedDocId)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>
        </div>
          );
        }
      },
   
  ]
 

  return (
    
    <div className="datatable">

<React.Fragment>
    
      <Dialog
        open={openDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this document ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
         Are you sure you want to delete this document ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size='small' variant='outlined' onClick={handleCloseDelete}>Cancel</Button>
          <Button size='small' variant='contained'  color="error" onClick={handleDelete} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
     
      <DataGrid
      
        
        rows={props.clients}
        columns={columns2}
        getRowId={(row) => row._id}
     
        localeText={{
 
 toolbarFilters: "Search",

}}
     components={{
 Toolbar: GridToolbarFilterButton ,
}}

      

       
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
       
      />

<React.Fragment>
<Dialog
        open={open}
        onClose={handleClose}
        
        PaperProps={{
          component: 'form',
          onSubmit: (event) => async () =>{
            event.preventDefault();
        
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const doc_name = formJson.documentName;
            const doc_description = formJson.documentDescription;
            const client_id = props.ridi;

         
            try {
              const response = await fetch(`${backEndUrl}/updateClient/addDoc`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ doc_name, doc_description, client_id}),
              });

       
              event.preventDefault();
            // handleClose();
          } catch (error) {
            console.error('Error:', error);
          }
        },
      }}
      >
        <DialogTitle>Ask for correction</DialogTitle>
        <DialogContent>
          <DialogContentText>
           <p>Use this form to request a correction on the document.</p> 
           <p style={{marginTop:'1%'}}> Don't forget to press the 'Send Email' button to notify your client.</p>
          </DialogContentText>
    
          <TextField
            InputLabelProps={{ shrink: true }}
            autoFocus
            required
            margin="dense"
            id="name"
            name="documentName"
            label="Document name"
            type="text"
            style={{marginTop:'6%'}}
            value={nameState ? undefined : docName}
            onChange={e => changeDocName(e)}
            fullWidth
            variant="standard"
  
       
          />
 

          <TextField
            InputLabelProps={{ shrink: true }}
            autoFocus
            required
            margin="dense"
            id="description"
            name="documentDescription"
            label="Requirements"
            type="text"
            // style={{marginTop:'6%'}}
            value={descriptionState ? undefined : docDescription}
            onChange={e => changeDocDescription(e)}
            fullWidth
            variant="standard"
            multiline
            rows={4}
      
          /> 



<div style={{marginTop:'6%'}}>
<label style={{marginBottom:'6%'}}>New deadline</label>
</div>
<div style={{marginTop:'3%'}}>
  <LocalizationProvider  dateAdapter={AdapterDayjs}>
      <DatePicker
      style={{marginLeft:'45%', marginTop:'6%'}}
      value={dateDeadline}
      format="YYYY/MM/DD" 
      
      onChange={handleDateChange}
       />
    </LocalizationProvider>

    {warningDate && <p style={{color: 'red',marginTop:'6%' }}>WARNING: The deadline has passed. Please select a date after today.</p>}
</div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={sendForm}>Submit</Button>
        </DialogActions>
      </Dialog>
                </React.Fragment>
    </div>
  );
};

export default TableDoc;