// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.5)
      ),
      url("https://images.pexels.com/photos/317355/pexels-photo-317355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
    background-size: cover;
  }

  .cena{
    font-size: 60px;
  }
  
  .registerTitle {
    font-size: 50px;
  }
  
  .registerForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .registerForm > label {
    margin: 10px 0;
  }
  
  .registerInput {
    padding: 10px;
    background-color: white;
    border: none;
    border-radius: 10px;
  }
  
  .registerInput:focus {
    outline: none;
  }
  
  .registerButton {
    margin-top: 20px;
    cursor: pointer;
    background-color: teal;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 10px;
    text-align: center;
  }
  
  .registerLoginButton {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: lightcoral;
    cursor: pointer;
    padding: 10px;
    border: none;
    color: white;
    border-radius: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/register/register.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB;;;;yHAIqH;IACrH,sBAAsB;EACxB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,4BAA4B;IAC5B,eAAe;IACf,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,mBAAmB;EACrB","sourcesContent":[".register {\n    height: calc(100vh - 50px);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background: linear-gradient(\n        rgba(255, 255, 255, 0.5),\n        rgba(255, 255, 255, 0.5)\n      ),\n      url(\"https://images.pexels.com/photos/317355/pexels-photo-317355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940\");\n    background-size: cover;\n  }\n\n  .cena{\n    font-size: 60px;\n  }\n  \n  .registerTitle {\n    font-size: 50px;\n  }\n  \n  .registerForm {\n    margin-top: 20px;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .registerForm > label {\n    margin: 10px 0;\n  }\n  \n  .registerInput {\n    padding: 10px;\n    background-color: white;\n    border: none;\n    border-radius: 10px;\n  }\n  \n  .registerInput:focus {\n    outline: none;\n  }\n  \n  .registerButton {\n    margin-top: 20px;\n    cursor: pointer;\n    background-color: teal;\n    color: white;\n    padding: 10px;\n    border: none;\n    border-radius: 10px;\n    text-align: center;\n  }\n  \n  .registerLoginButton {\n    position: absolute;\n    top: 60px;\n    right: 20px;\n    background-color: lightcoral;\n    cursor: pointer;\n    padding: 10px;\n    border: none;\n    color: white;\n    border-radius: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
