// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxWraper {
  padding-top: 1%;
  padding-right: 3%;
}

.boldText {
  font-weight: bold;
  color: black; /* Example of additional styling */
}

.marginTop {
  margin-top: 2%;
}

.box {
  height: 100%;
  border-radius: 10px;
  padding: 2%;
  padding-left: 45px;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
}

.pending-status {
  background-color: rgba(255, 0, 0, 0.05);
  color: crimson;
  border: 1px solid rgba(213, 5, 5, 0.286);
  border-radius: 5px;
}

.uploaded-status {
  background-color: rgba(0, 128, 0, 0.05);
  color: green;
}`, "",{"version":3,"sources":["webpack://./src/component/docReview/docReview.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;AACJ;;AAGA;EACI,iBAAA;EACA,YAAA,EAAA,kCAAA;AAAJ;;AAGA;EACI,cAAA;AAAJ;;AAIA;EACG,YAAA;EACC,mBAAA;EACA,WAAA;EACA,kBAAA;EAEA,sDAAA;AADJ;;AAIA;EACI,uCAAA;EACA,cAAA;EACA,wCAAA;EACA,kBAAA;AADJ;;AAMA;EACI,uCAAA;EACA,YAAA;AAHJ","sourcesContent":[".boxWraper{\n    padding-top: 1%;\n    padding-right: 3%;\n    \n}\n\n.boldText {\n    font-weight: bold;\n    color: black; /* Example of additional styling */\n  }\n\n.marginTop{\n    margin-top: 2%;\n}\n\n\n.box {\n   height: 100%;\n    border-radius: 10px;    \n    padding: 2%;\n    padding-left: 45px;\n    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n}\n\n.pending-status{\n    background-color: rgba(255, 0, 0, 0.05);\n    color: crimson;\n    border: 1px solid rgba(213, 5, 5, 0.286);\n    border-radius: 5px;\n   \n    \n    \n}\n.uploaded-status{\n    background-color: rgba(0, 128, 0, 0.05);\n    color: green;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
