// DocumentViewer.jsx
import React from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useState , useEffect} from 'react'
import { Dialog, DialogPanel, DialogTitle , DialogBackdrop} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon} from '@heroicons/react/20/solid'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; 
import utc from 'dayjs/plugin/utc';



const DocumentViewer = () => {
    


  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS
  const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const [open, setOpen] = useState(false)
    const [showValidatedNotif, setShowValidatedNotif] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deadline, setDeadline] = useState(dayjs(queryParams.get('deadline')));

    const [warningMessage, setWarning] = useState(false)

    const handleDateChange = (date) => {
      setDeadline(date); // Save the selected date in the state variable
    };

    const[displayInput, setDisplayInput] = useState(false)

    const [newRequirements, setNewRequirements] = useState("")
    const [isValid, setIsValid] = useState()
    const [isChecked, setisChecked] = useState(false);

    
    const documentUrl = queryParams.get('url');
    const docName = queryParams.get('docName'); 
    const docDescription = queryParams.get('docDescription');
    const docID=queryParams.get('docID');
    

    const [docNameInInput, setDocNameInInput] = useState(docName); 

    const userId = queryParams.get('userId')
            
    dayjs.extend(utc);
    useEffect(() => {
      // Get the current date in UTC
      const currentDate = dayjs.utc();
      // Check if the current date is after the deadline
      if (currentDate.isAfter(deadline)) {
        setWarning(true);
      }  else {
        setWarning(false)
      }
  }, [deadline])

    const submit = async () => {
                setLoading(true);
        try {
            const response = await axios({
                method: 'POST',
                url: `${backEndUrl}/update/addDocument/${userId}`, // Use the actual userId here
                data: {
                    docName: docNameInInput,
                    docDescription: newRequirements,
                    docValid : isValid,
                    docID : docID,
                    deadline: deadline,
                },
                withCredentials: true, // Include credentials if needed
            });
                        setShowValidatedNotif(true)
        } catch (error) {
            console.error('Error submitting data:', error);
            // Handle error (e.g., show an error message)
        } finally {
            setLoading(false);
        }
    };

  



    return (
        <div style={{ display: 'flex', height: '100vh' }}>
        {/* Iframe Container */}
        <div style={{ flex:1, height: '100vh', overflowY: 'auto' }}>
          <iframe
            style={{ width: '100%', height: '100%', border: 'none' }}
            src={documentUrl}
            title="Document Viewer"
          />
        </div>

        {/* Document Details */}
<div style={{width:'30%', wordWrap: 'break-word'}}>

        <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="h-0 flex-1 overflow-y-auto">
                  <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                    <div className="flex items-center justify-between">
                   
                      <div className="ml-3 flex h-7 items-center">
                    
                      </div>
                    </div>
                    <div className="mt-1">
                      <p className="text-m text-indigo-100">
                      {docName} 
                      </p>
                      <p className="text-m text-indigo-100">
                      {docDescription}
                      </p>
                  
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col justify-between">
                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                      <div className="space-y-6 pb-5 pt-6">
                   
                        <fieldset>
                          <legend className="text-sm font-medium leading-6 text-gray-900">This document is : </legend>
                          <div className="mt-2 space-y-1">
                            <div className="relative flex items-start">
                              <div className="absolute flex h-6 items-center">
                                <input
                                
                          
                                  id="privacy-public"
                                  name="privacy"
                                  type="radio"
                                  aria-describedby="privacy-public-description"
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  onClick={() => {
    setDisplayInput(false); 
    setisChecked(true); 
    setIsValid(true); 
}}
                                />
                              </div>
                              <div className="pl-7 text-sm leading-6">
                                <label htmlFor="privacy-public" className="font-medium text-gray-900">
                                  Good
                                </label>
                                <p id="privacy-public-description" className="text-gray-500">
                                It has no error and ready for submission.
                                </p>
                              </div>
                            </div>
                            <div>
                              <div className="relative flex items-start">
                                <div className="absolute flex h-6 items-center">
                                  <input
                                    id="privacy-private-to-project"
                                    name="privacy"
                                    type="radio"
                                    aria-describedby="privacy-private-to-project-description"
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                             onClick={() => {
                                              setDisplayInput(true); 
                                              setisChecked(true); 
                                              setIsValid(false); 
                                          }}
                                  />
                                </div>
                                <div className="pl-7 text-sm leading-6">
                                  <label htmlFor="privacy-private-to-project" className="font-medium text-gray-900">
                                  Incorrect and/or incomplete.
                                  </label>
                                  <p id="privacy-private-to-project-description" className="text-gray-500">
                                    
                                  The document is not acceptable, and modifications are needed.
                                  </p>
                                </div>
                              </div>
                            </div>

                            {displayInput && (
                                <div>
                                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                Reasons why the document is incorrect 
                          </label>
                                    <div className="mt-2">
                                        <textarea
                                        id="description"
                                        name="description"
                                        rows={4}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        defaultValue={docDescription}
                                        onChange={(e) => {
        setNewRequirements(e.target.value); // Update newRequirements
     
    }} 
                                        />
                                    </div>
                                    </div>
                                    )}
           
                          </div>
                        </fieldset>
                      </div>

             {/*          <div className="pb-6 pt-4">
         
                        <div className="mt-4 flex text-sm">
                          <a href="#" className="group inline-flex items-center text-gray-500 hover:text-gray-900">
                            <QuestionMarkCircleIcon
                              aria-hidden="true"
                              className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            />
                            <span className="ml-2">Learn more about sharing</span>
                          </a>
                        </div>
                      </div> */}

                    </div>
                  </div>
                </div>
                <div className="flex flex-shrink-0 justify-end px-4 py-4">
              
                  <button
                    type="button"
                    className={`ml-4 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                        isChecked ? "bg-indigo-600 hover:bg-indigo-500" : "bg-indigo-200 cursor-not-allowed opacity-50"
                    } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                    onClick={() => {
                        if (isChecked) {
                            displayInput ? setOpen(true) : submit(); // Proceed with submission if isChecked is true
                        }
                    }}
                    disabled={!isChecked} // Disable the button if isChecked is false
                  >
                    Save
                  </button>
                </div>
              </form>

</div>






      
            <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                Request for correction.
                </DialogTitle>

                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                  Here is the information that will be used to request a correction from the client.
                  </p>
                </div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
        Document name
      </label>
      <div className="mt-2">
        <input
          id="email"
          value={docNameInInput}
          name="email"
          type="email"
          placeholder="you@example.com"
          className="block w-full pl-2 pr-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(e) => setDocNameInInput(e.target.value)} 
        />
      </div>

      <label htmlFor="comment" className="mt-2 block text-sm font-medium leading-6 text-gray-900">
        
        New document requirements.
      </label>
      <div className="mt-2">
        <textarea
          id="comment"
          name="comment"
          rows={4}
          className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          defaultValue={newRequirements || docDescription || ""} 
          onChange={(e) => setNewRequirements(e.target.value)} 
        />
      </div>
      <div className="mt-2">
                  <p className="text-sm text-gray-500">
                  The deadline for receiving all required documents.
                  </p>
                </div>
      <div className="mt-2">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
      <DatePicker
label='deadline'
value={deadline}
format="YYYY/MM/DD" 
onChange={handleDateChange}
 />

      </DemoContainer>
    </LocalizationProvider>
      </div>
      <div className="mt-3 text-sm text-red-500">
      {warningMessage ? <p>The deadline has passed. Please choose a future date.</p> : null}
      </div>


           
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={() => {
        submit(); // Call the submit function
        setOpen(false); // Close the dialog after submission
    }}
                className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
              >
                Confirm
              </button>
              <button
                type="button"
                data-autofocus
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>


{/* Notification if succesfuly saved */}

         {/* Loading Indicator */}
         {loading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
                <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
                </div>
            )}


    <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition show={showValidatedNotif}>
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="shrink-0">
                    <CheckCircleIcon aria-hidden="true" className="size-6 text-green-400" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">Successfully saved!</p>
                   {/*  <p className="mt-1 text-sm text-gray-500">Information on this document has been updated.</p> */}
                  </div>
                  <div className="ml-4 flex shrink-0">
                    <button
                      type="button"
                      onClick={() => {
                        setShowValidatedNotif(false)
                      }}
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon aria-hidden="true" className="size-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>

        </div> 
    );
};

export default DocumentViewer;
