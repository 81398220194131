import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import StoreIcon from "@mui/icons-material/Store";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FolderIcon from '@mui/icons-material/Folder';

import { Link, useParams } from "react-router-dom";

import { useContext } from "react";


const SidebarClient = (props) => {


  const encodedId = encodeURIComponent(useParams);

  // const { dispatch } = useContext(DarkModeContext);
  const { id } = useParams();

  return (
    <div className="sidebar">
      <div className="top">
       
      <span className="logo text-sm mt-1 ml-2 mr-2">
    {props.companyName ? props.companyName : 'DocUP'}
</span>

      </div>
      <hr className="mt-2"/>
      <div className="center">
        <ul>
          <p className="title">Your documents</p>
 
          <Link to={`/docUpload/` + id } style={{ textDecoration: "none" }}>
            <li>
              <FolderIcon className="icon" />
              <Link to={`/docUpload/` + id } style={{ textDecoration: "none" }}>
              <span>Document</span>
              </Link>
            </li>
          </Link>
 
        </ul> 
      </div>
      <div className="bottom">

      </div>
    </div>
  );
};

export default SidebarClient;