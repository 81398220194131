// src/components/OAuthCallback.js

import React, { useEffect } from 'react';
const OAuthCallback = () => {
  useEffect(() => {
    // Get the query string from the URL
        const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');

    if (code) {
      // Send the authorization code to the backend
      fetch('http://localhost:9000/oauth2callback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ code }),
        credentials: 'include'
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to retrieve tokens');
        }
        return response.json();
      })
      .then(data => {
                // Handle successful token retrieval
        window.location.href = '/homepage/' + data.userId
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }
  }, []);

  return (
    <div>
      <h1>Processing OAuth Callback...</h1>
    </div>
  );
};
export default OAuthCallback;
