import "./clientUploadDoc.scss"
import SidebarClient from "../../component/sideBarClient/SideBarClient";
import Navebar from "../../component/navbar/Navbar";
import Axios from "axios";
import { useState, useEffect } from "react";
import DocReview from "../../component/docReview/docReview";
import { useParams } from "react-router-dom";

export default function ClientUploadDoc () {

    const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

    const [data, setData] = useState();
    const [numberDoc, setNumberDoc] = useState('');
    const { id } = useParams()

    useEffect(() => {
        Axios ({
           method: "GET",
          withCredentials: true,
          url: `${backEndUrl}/auth`,
     
         
          }).then((res) => {
           setData(res.data);
         
        
    
          
           
          });


     },[]) 


      
      useEffect(() => {
        // This code will run whenever `data` changes
        if (data) {
          const notUploadedDocuments = data.documents.filter(
            (document) => document.status === "not uploaded"
          );
      

          setNumberDoc(notUploadedDocuments.length);
        }
      }, [data]);

     
    

     function displayDocuments(doc) {
        return <DocReview 
            name={doc.name}
            rid={doc._id}
            description={doc.description}
            path={doc.doc_upload}
            consultantAttachment={doc.doc_upload_consultantSide}
            userID={id}
            status={doc.status}
            review={doc.review}
            reason={doc.reason}
            clientName={data.firstName}
            storageUsed={data.consultantStorage}
            language={data.language}
            
        />
    }

    if (data === undefined) {
    

        return (<>Still loading...</>)
    }
    
    if (!data){
        return 
        <h1>Not allowed</h1>
        
    } 

    if (data){
   
       
       
return (
    <div>

    
     

        <div className="home">
        <SidebarClient
           SidebarClient 
           companyName={data.companyName}
          
        />
   

    <div className="homeContainer">
    {/* <Navebar/> */}
    
    <div className="clientContainer">

<div style={{marginTop:'7%'}}>
  {data.language === 'french' ? (
    <span>
    <h2 className="text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Bonjour {data.firstName}, <br /> </h2>
      <div className="marginTop">
        {numberDoc !== 0 ? (
          <span className="text-gray-700 ">
            Merci de téléverser les documents suivants : <br />
            <p className="text-gray-700 mt-4">
              Il vous reste <span  className="text-red-600 font-bold">{numberDoc} documents </span>à téléverser.
            </p>
           {/*  <p style={{ marginTop: '1%' }}>
              Veuillez noter que la vérification de votre dossier ne pourra se faire que lorsque vous aurez téléversé <span style={{ color: 'red', fontWeight: 'bold' }}>tous les documents.</span>
            </p> */}
          </span>
        ) : (
          <span className="text-gray-700">Merci d'avoir téléversé tous les documents. Nous reviendrons vers vous sous peu.</span>
        )}
      </div>
    </span>
  ) : (
    <span className="block">
        <h2 className="text-xl font-semibold text-gray-800">Hi {data.firstName},</h2>
        <div className="mt-4">
            {numberDoc !== 0 ? (
                <div>
                    <p className="text-gray-700 ">Please upload the following documents:</p>
                    <p className="mt-2 text-gray-700">
                        You have <span className="text-red-600 font-bold">{numberDoc} documents</span> left to upload.
                    </p>
                {/*     <p className="mt-2">
                        Please note that your file verification can only take place once you have uploaded <span className="text-red-600 font-bold">all the documents.</span>
                    </p> */}
                </div>
            ) : (
                <p className="text-gray-700">Thank you for uploading all the documents. We will get back to you shortly.</p>
            )}
        </div>
    </span>
  )}
</div>



</div>

   <div className="relative">
      <div aria-hidden="true" className="absolute inset-0 flex items-center">
        <div className="w-11/12 mx-auto border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-3 text-base font-semibold text-gray-900">{data.language === 'french' ? 'Votre liste de documents' : 'Your document checklist'}</span>
      </div>
    </div>

<div className="overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6">


{data.documents.map(displayDocuments)} 
    </div>
    </div>
    </div>

    </div>
    </div>

)
}
}
