import { useState, useEffect } from "react";
import Axios from "axios";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import ViewTemplateTable from "../../component/viewTempComp/viewTempComp";
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function ViewTemplate() {

  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

    const [data, setData] = useState();
    const [templateName, setTemplateName] = useState();
    const [templateFixedName, setTemplateFixedName] = useState();
    const [templateId, setTemplateId] = useState(useParams().id);

    const [open, setOpen] = React.useState(false);

    const [newDocName, setNewDocName] = React.useState('');
    const [newDocDescription, setNewDocDescription] = React.useState('');

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


    const [openName, setOpenName] = React.useState(false);

    const handleClickOpenName = () => {
      setOpenName(true);
    };
  
    const handleCloseName = () => {
      setOpenName(false);
    };


    const addDocument = async (e) => {
         
         try {
            await Axios({
                method: 'POST',
                withCredentials: true,
                data: {
                    template_id: templateId,
                    document_name: newDocName,
                    document_description: newDocDescription,
                },
                url: `${backEndUrl}/updateClient/addDocToTemplate`,
            });
            
            window.location.reload();
        } catch (error) {
            
    
         }
    }

    const changeTemplateName = async (e) => {
      //  e.preventDefault();
       
     try {
        await Axios({
            method: 'PUT',
            withCredentials: true,
            data: {
                template_id: templateId,
                template_name: templateName,
            },
            url: `${backEndUrl}/updateClient/updateTemplateName`,
        });
        
        window.location.reload();
    } catch (error) {
        

     }
    }

    useEffect(() => {
        Axios ({
           method: "GET",
          withCredentials: true,
            url: `${backEndUrl}/auth/consultantLog`,
     
         
          }).then((res) => {
           setData(res.data);       
          });
     }, []) 

 

     // loop through the data to get the template name

    useEffect(() => {
  if (data !== undefined) {
    for (let i = 0; i < data.docTemplates.length; i++) {
      if (data.docTemplates[i]._id === templateId) {
        setTemplateFixedName(data.docTemplates[i].name);
        break; // Exit loop once template name is found
      }
    }
  }
}, [data, templateId]);

     if (data === undefined) {

        return (<>Still loading...</>)
    }
    
    else {

    return (
        <div>
             <div className="home">

  

    <div className="homeContainer">



    <div>
      <div className="mt-8 ml-8" >
        <nav aria-label="Back" className="sm:hidden">
          <a href="#" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon aria-hidden="true" className="-ml-1 mr-1 h-5 w-5 shrink-0 text-gray-400" />
            Back
          </a>
        </nav>
        <nav aria-label="Breadcrumb" className="hidden sm:flex">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  List
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon aria-hidden="true" className="h-5 w-5 shrink-0 text-gray-400" />
                <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  Templates
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon aria-hidden="true" className="h-5 w-5 shrink-0 text-gray-400" />
                <a href="#" aria-current="page" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                {templateFixedName}
                </a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl/7 ml-8 mt-2 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {templateFixedName}
          </h2>
        </div>
        <div className="mt-4 flex shrink-0 md:ml-4 md:mt-0">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={handleClickOpen}
          >
            Edit
          </button>
          <button
            type="button"
            className="ml-3 mr-8 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleClickOpenName}
          >
            Add document
          </button>
        </div>
      </div>
    </div>



   <ViewTemplateTable
              clients={data.docTemplates}
      
           
        />  
        </div>
        
        </div>

        <React.Fragment>
   
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            
            handleClose();
          },
        }}
      >
        <DialogTitle>
        Rename Your Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Please enter the new name for your template.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Template name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>Cancel</Button>
          <Button variant='contained' onClick={changeTemplateName}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

    <React.Fragment>

      <Dialog
        open={openName}
        onClose={handleCloseName}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            
            handleClose();
          },
        }}
      >
        <DialogTitle>Add a document</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Please define the document name and its requirements.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="Nom"
            label="Document name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setNewDocName(e.target.value)}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="description"
            name="description"
            label="Requirements"
            type="text"
            fullWidth
            variant="standard"
            multiline
            onChange={(e) => setNewDocDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseName}>Annuler</Button>
          <Button variant='contained' onClick={addDocument}>Envoyer</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
      
        </div>
    )
}
}

