// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings {
  padding: 20px;
  margin: 20px;
}

.abonnement {
  margin: 20px;
  margin-top: 50px;
}

.annuler {
  margin: 20px;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/Setting.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;AACJ;;AAEA;EACI,YAAA;EACA,gBAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;AACJ","sourcesContent":[".settings{\n    padding: 20px;\n    margin: 20px;\n}\n\n.abonnement {\n    margin: 20px;\n    margin-top: 50px;\n}\n\n.annuler {\n    margin: 20px;\n    display: flex;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
