import { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { useParams, useLocation} from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import Documents from '../../component/document/Document'
import Clients from '../../component/client/Client'
import Input from "../../component/input/Input";
import Notifications from "../../component/notification/Notification";
import Sidebar from "../../component/sidebar/Sidebar";
import Navebar from "../../component/navbar/Navbar";
import "./Homepage.scss"

import Datatable from "../../component/datatable/Datatable";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CircularProgress from '@mui/material/CircularProgress';
import { Dialog as DialogT, DialogBackdrop, DialogPanel, DialogTitle as DialogTitleT } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { InboxIcon } from "@heroicons/react/20/solid";

import { MsalProvider, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, loginRequest } from '../../authConfig';
import { InteractionRequiredAuthError } from "@azure/msal-browser";






export default function HomepageCons() {

  /* const msalInstance = new PublicClientApplication(msalConfig); */
  

    const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

    const [data, setData] = useState(null);

    const [openGmail, setOpenGmail] = useState(true)
  

    const [open, setOpen] = React.useState(false);

    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const handleCloseBackdrop = () => {
      setOpenBackdrop(false);
    };
    const handleOpenBackdrop = () => {
      setOpenBackdrop(true);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  

    const driverObj = driver({
    

   

      onPrevClick:() => {
        
        handleClickOpen();
        // Implement your own functionality here
      
      },
      
      onComplete:() => {
        
     },
      
      
        steps: [

          { popover: { title: 'Welcome to DocUp !', description: 'Here’s a quick guide to help you get familiar with the platform. You’ll see—it’s very straightforward!', 
          side: "left", align: 'start' ,   }},
          { element: '#new-client', popover: { title: 'Create a client', description: 'To get started, click this button to create a new client.', 
          side: "left", align: 'start',    showButtons: []  }},
         
         
        ],

        onDestroyStarted: () => {
           //setOpen(true);
          
           if ( window.confirm("Are you sure you want to exit the guide ?")) {
            driverObj.destroy();
          }
            
          
          
        },

        onDestroyed: async () => {
          try {
            const response = await Axios({
              method: "PUT",
              withCredentials: true,
              url: `${backEndUrl}/update/showGuide/${data.id}`,
              data: {
                showGuide: false
              }
            });
            
            // Optionally, proceed with further actions after the update
          } catch (error) {
            console.error('Failed to update guide status:', error);
            // Handle errors, maybe inform the user
          }
        } 
      });
/* 
      useEffect(() => {
        // Start the tour
        driverObj.drive();
    
        // Cleanup function to stop the tour when the component unmounts or route changes
        return () => driverObj.destroy();
      }, [location]); // Depend on location to react to route changes
     */

      // driverObj.drive();
 
useEffect(() => {
    Axios ({
       method: "GET",
      withCredentials: true,
        url: `${backEndUrl}/auth/consultantLog`,
 
     
      }).then((res) => {
       setData(res.data);       
      });
 }, []) 

 const { instance, accounts } = useMsal();


 //function to send email using Microsoft API


/*  const handleSendEmail = async (userId) => {
    await msalInstance.initialize(); */

/*  const handleSendEmail = async (userId) => {
  console.log('Sending email for user: ' + userId); */
/*   await msalInstance.initialize(); */


/*   const accounts = msalInstance.getAllAccounts(); */

/* if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]); // Set the first account as active
} else {
  console.error("No accounts found. Please sign in.");
  return; // Optionally, redirect to login flow
} */

  // Define the MSAL request to acquire the token silently
/*   const tokenRequest = {
    scopes: ["https://graph.microsoft.com/mail.send"],  // Scopes needed for sending email
    account: msalInstance.getActiveAccount()  // Ensure you're sending the request for the logged-in user
  };
 */
 /*  try {
    // Try to acquire the token silently
    const tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
    // tokenResponse.expiresOn = new Date(Date.now() - 1000); // Force token expiration to test fallback
    const accessToken = tokenResponse.accessToken;

    // Now send the email using the token acquired
    const response = await Axios.post('http://localhost:9000/users/send-email-microsoft', {
      userId,
      toEmail: 'mesnosa95@gmail.com',  // Replace with the actual recipient email
      subject: 'Nous attendons vos documents',
      body: 'Bonjour Amine, vous trouverez la liste des documents que nous attendons de votre part. Merci de nous les envoyer dès que possible.',
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`  // Pass the access token in the Authorization header
      }
    });

      } catch (error) {
    // Handle token acquisition failure (e.g., InteractionRequiredAuthError)
    if (error instanceof InteractionRequiredAuthError) {
            
      // Fallback to acquire token via a popup if silent acquisition fails
      try {
        const tokenResponse = await msalInstance.acquireTokenPopup(tokenRequest);
        const accessToken = tokenResponse.accessToken;

        // Retry sending the email with the new token
        const response = await Axios.post('http://localhost:9000/users/send-email-microsoft', {
          userId,
          toEmail: 'mesnosa95@gmail.com',  // Replace with the actual recipient email
          subject: 'Nous attendons vos docuements',
          body: 'Bonjour Amine, vous trouverez la liste des documents que nous attendons de votre part. Merci de nous les envoyer dès que possible.',
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`  // Use the new access token
          }
        });

              } catch (popupError) {
        console.error('Error acquiring token via popup:', popupError);
      }
    } else {
      console.error('Error sending email:', error.response ? error.response.data : error.message);
    }
  }
};
 */
 //end of function to send email using Microsoft API
/* 
 const handleLogin = async () => {
   await msalInstance.initialize(); 
  msalInstance.loginPopup(loginRequest).then((response) => {
        
   
    msalInstance.setActiveAccount(response.account);

   
    fetch('http://localhost:9000/auth/AzurToken', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: response.accessToken
      })
    }).then(res => res.json())
      .then(data => console.log('Token saved on the backend', data))
      .catch(err => console.error('Error saving token', err));
  }).catch(error => {
    console.error("Login failed: ", error);
  });
};
 */
 

 useEffect(() => {

  if (data && data.showGuide) {
    driverObj.drive();
  }
}, [data]);

const handleUpgrade = async () => {

    try {
      const response = await Axios({
        method: "POST",
        data: {
          consultantEmail: data.email,
          consultantID: data.id
        },
        withCredentials: true,
        url: `${backEndUrl}/update/backend`,
      });

     if (response.status !== 200) {
        throw new Error('Network response was not ok');
      } 

      const body = response.data;
      // Handle the response data
      window.location.href = body.url;
    } catch (error) {
      console.error('Error:', error);
    }
  
};
 

function createNotif(notif) {
    return <Notifications 
        message={notif.message}
        rid={notif._id}
        date={notif.date}
        closed={notif.closed}
        type={notif.type}
    />
}





if (data === undefined || !data) {

    return (
<div style={{marginTop:'20%', marginLeft:'50%' }}>  
      <CircularProgress color="secondary" />
      </div>      
      )

}


else {




    return(

    
   
    
      


   
        
       
      <div className="listContainer">

{/* <button onClick={() => handleSendEmail(data.id)}>
            Send Email
        </button> */}


        {/* Dialog for emamil */}
{/* 
<DialogT open={openGmail} onClose={setOpenGmail} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-8 py-8 text-left shadow-xl transition-all sm:w-full sm:max-w-2xl sm:py-32"
          >
            <div>
           <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <InboxIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
              </div> 
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitleT as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  Intégrer votre boîte mail
                </DialogTitleT>
                <div className="mt-2">
                  <p className="text-sm text-gray-700">
                    Pour commencer à utiliser DocUp, nous vous conseillons d'intégrer votre boîte mail. Cela vous permettra d'envoyer des emails à vos clients directement depuis votre adresse email personelle.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
         
            <div>
      <h1>Microsoft Email Integration</h1>
    
      <button onClick={handleLogin}>Login with Microsoft</button>

    </div>
     
            <a
                  href="http://localhost:9000/auth/google"
                  className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                >
                  <svg viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5">
                    <path
                      d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                      fill="#EA4335"
                    />
                    <path
                      d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                      fill="#34A853"
                    />
                  </svg>
                  <span  className="text-sm font-semibold leading-6">Google</span>
                </a>
              <button
                type="button"
                onClick={() => setOpenGmail(false)}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
              >
                Deactivate
              </button>
              <button
                type="button"
                data-autofocus
                onClick={() => setOpenGmail(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </DialogT>
 */}
      <div>
      {/* <h1 id="guide">Bonjour {data.name} </h1> */}

    
      
      <div>
  {data.notifications.length === 0 || !data.notifications.some(notification => notification.closed === false) ? (
  null
  ) : (
    <div>
      <p style={{ marginTop: '2%' }}>Notifications</p>
      <div  >
        <div className="divide-gray-900 border-2 rounded-lg bg-white shadow max-w-[1200px] max-h-40 overflow-y-auto">
          {data.notifications.map(createNotif)}
        </div>
      </div>
    </div>
  )}
</div>

    
    
   


   
        <Datatable 
              clients={data.clients}
              rid={data.id}
              consultantStorage={data.storageUsed}
              getRowId={(row) => row.data.clients._id}
              getRowClient={(row) => row.data.clients}

           
        />
  
  
        


        <div> 
     

      
      

      
     



   

        </div>

 </div>
 <React.Fragment>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
  
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Do you really want to quit ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
 </div>
 
    ) 
}
  
}