// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleHome {
  margin-top: 2%;
  margin-left: 2%;
}

.titleHomeKid {
  margin-top: 2%;
  margin-left: 2%;
}

.marginTop {
  margin-top: 1%;
}`, "",{"version":3,"sources":["webpack://./src/pages/FormToComplete/FormToComplete.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;AACJ;;AACA;EACI,cAAA;EACA,eAAA;AAEJ;;AACA;EACI,cAAA;AAEJ","sourcesContent":[".titleHome{\n    margin-top: 2%;\n    margin-left: 2%;\n}\n.titleHomeKid{\n    margin-top: 2%;\n    margin-left: 2%;\n}\n\n.marginTop{\n    margin-top:1%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
