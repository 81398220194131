import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Sidebar from '../../component/sidebar/Sidebar';
import Navebar from '../../component/navbar/Navbar';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export default function Support() {

    const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS
    const [data, setData] = useState(null);

    useEffect(() => {
        Axios ({
           method: "GET",
          withCredentials: true,
            url: `${backEndUrl}/auth/consultantLog`,
     
         
          }).then((res) => {
           setData(res.data);       
          });
     }, []) 

     

     const [subject, setSubject] = useState('');
     const [problem, setProblem] = useState('');
   
     const handleSubmit = (event) => {
       event.preventDefault();
      
         Axios({
              method: "POST",
              data: {
                 subject: subject,
                 problem: problem,
                 consultantEmail: data.email
              },
              withCredentials: true,
              url: `${backEndUrl}/users/support`,
            }).then((res) => {
              
              if (res.data === 'success') {
                 alert('Support ticket submitted');
                 setSubject('');
                 setProblem('');
              } else {
                 alert('Failed to submit support ticket');
              }
            });

     };


if (data === undefined || !data) {

    return (<>Still loading...</>)
}


else {




    return(


        <div className="home">


        <div className="homeContainer">
    
        
       
      <div >

      <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { marginBottom: 2, width: '100%' },
        maxWidth: '500px',
        margin: '0 auto',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '50px'
      }}
      onSubmit={handleSubmit}
    >
      <Typography variant="h6" gutterBottom>
      Need help? We're here to assist you!
      </Typography>
      <Typography variant="body2" gutterBottom>
      Please fill out the form below to submit a support ticket.
        </Typography>
        <Typography style={{marginTop:'2%'}}variant="body2" gutterBottom>
        Please provide as many details as possible so we can assist you in the best possible way.
        </Typography>
      <TextField
      style={{marginTop:'4%'}}
        label="Subject"
        variant="outlined"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <TextField
        label="Problem / Question / Comment / Other"
        variant="outlined"
        multiline
        rows={4}
        value={problem}
        onChange={(e) => setProblem(e.target.value)}
      />
      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
    </Box>

     </div>
     </div>
     </div>

    )
}

}

