import React, { useState, useEffect } from 'react';
import "./Setting.scss";

import axios from "axios";



import Sidebar from "../../component/sidebar/Sidebar";
import Navebar from "../../component/navbar/Navbar";
import Button from '@mui/material/Button';

const SettingsPage = () => {

    const [data, setData] = useState(null);
    const [cancellSucessfull, setCancellSucessfull] = useState(false);

    const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

    useEffect(() => {
        axios ({
           method: "GET",
          withCredentials: true,
            url: `${backEndUrl}/auth/consultantLog`,
     
         
          }).then((res) => {
           setData(res.data);       
          });
     }, []) 

     
     

    const handleCancelSubscription = async () => {
     
        try {
            const response = await axios({
              method: "POST",
              withCredentials: true,
              url: `${backEndUrl}/users/cancel-subscription`,
              data: {
                subscriptionID: data.subscriptionID,
                consultantEmail: data.email,
              }
            });

            if (response.status === 200) {
              
              setCancellSucessfull(true);
              // redirect to /
            
            }

            } catch (error) {
            console.error('Failed to update guide status:', error);

            }
            }


if (!data) {
  return <div>Loading...</div>
}

    return(

    
   
    
      


        <div className="home">
       
     

        <div className="homeContainer">
  
        
       
      <div className="settings">

      
      <div>

      <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base/7 font-semibold text-gray-900">Account Settings</h2>
              <p className="mt-1 text-sm/6 text-gray-500">
              Here you can view your account details.
              </p>

              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm/6">
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Full name</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{data.name}</div>
                  {/*   <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Update
                    </button> */}
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{data.email}</div>
                 {/*    <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Update
                    </button> */}
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Company</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">
                        {data.companyName ? data.companyName : null}
                    </div>
                 {/*    <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Update
                    </button> */}
                  </dd>
                </div>
          
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Plan</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{data.subscription}</div>
                    {data.subscription !== 'free' ?
            
            <button
                type="button"   className="w-1/8 rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                onClick={handleCancelSubscription} // Replace with your cancel subscription logic
            >
                Cancel 
            </button> : 
          null}
                  </dd>
                </div>
              </dl>
            </div>

            
        </div>

       

     
    
        </div>
   


   
  
  
        </div>
        


        <div> 
     

      
      

      
     



   

        </div>

 </div>

 </div>
 
    ) 
};

export default SettingsPage;
