// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: auto;
  clear: both;
  height: auto;
}

.home {
  display: flex;
}
.home .homeContainer {
  flex: 6 1;
}
.home .homeContainer .widgets,
.home .homeContainer .charts {
  display: flex;
  padding: 20px;
  gap: 20px;
}
.home .homeContainer .charts {
  padding: 5px 20px;
}
.home .homeContainer .listContainer {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  margin: 20px;
}
.home .homeContainer .listContainer .listTitle {
  font-weight: 500;
  color: gray;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/homepage-cons/Homepage.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;AAAF;;AAGA;EACI,aAAA;AAAJ;AAEI;EACE,SAAA;AAAN;AAEM;;EAEE,aAAA;EACA,aAAA;EACA,SAAA;AAAR;AAGM;EACE,iBAAA;AADR;AAIM;EAEE,sDAAA;EACD,aAAA;EACC,YAAA;AAFR;AAIQ;EACE,gBAAA;EACA,WAAA;EACA,mBAAA;AAFV","sourcesContent":["\n.notification-container {\n  display: flex;\n  flex-direction: column;\n  align-items: start;  \n  overflow: auto;\n  clear: both;\n  height: auto;  \n}\n\n.home {\n    display: flex;\n  \n    .homeContainer {\n      flex: 6;\n  \n      .widgets,\n      .charts {\n        display: flex;\n        padding: 20px;\n        gap: 20px;\n      }\n  \n      .charts {\n        padding: 5px 20px;\n      }\n  \n      .listContainer {\n        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n       padding: 20px;\n        margin: 20px;\n  \n        .listTitle {\n          font-weight: 500;\n          color: gray;\n          margin-bottom: 15px;\n        }\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
