import "./Datatable.scss";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
// import { userColumns, userRows } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { GridToolbar } from "@mui/x-data-grid";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import Axios from "axios";
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BUTTON from '@mui/material/Button';


const Datatable = (props) => {

  
  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [clientID, setClientID] = useState();
  const [clientDocuments, setClientDocuments] = useState();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (client) => {
    
    setClientID(client._id);
    setClientDocuments(client.documents);
    setOpen(true);
  };
   const handleClose = () => {
    setOpen(false);
  };

  // handleConfirm does the actual deleting of the client

  const handleConfirm = async(id)=> {
 

    try {
      await Axios({
        method: 'DELETE',
        withCredentials: true,
        data: {
           // template_id: a.id,
            client_id: clientID,
            clientDocuments:clientDocuments,
            consultantStorage: props.consultantStorage,
          
        },
        url: `${backEndUrl}/updateClient/deleteClient`,
      });
      
      setShowConfirmation(false);
    window.location.reload();
    } catch (error) {
      
      
    }  
    
  };

  // handleCancel hides the confirmation dialog
  const handleCancel = () => {
    setShowConfirmation(false);
  };

  

  

  const columns2 = [
  
    { field: "client_name", headerName: "Name", width: 180 ,
    valueGetter: (params) =>
      `${params.row.client_firstName || ""} ${params.row.client_lastName || ""}`,
  },

/*     { field: "program", headerName: "Programme", width: 150,  valueGetter: (params) =>
    `${params.row.program || ""}` }, */

    { field: "docStatus", headerName: "Status", width: 140,     valueGetter: (params) => {
      const status = params.row.fileStatus || "";
      if (status === "uncomplete") {
        return "Incomplete";
      } else if (status === "late") {
        return "Late";
      }
       else if (status === "complete") {
        return "Complete";
      }
      return status;
    },
    cellClassName: (params) => {
      const status = params.row.fileStatus || "";
      if (status === "uncomplete") {
        return "uncompleted-cell"; // Apply your CSS class for uncomplete status
      } else if (status === "to review") {
        return "to-review-cell"; // Apply your CSS class for 'to review' status
      } else if (status === "complete") {
        return "complete-cell"; // Apply your CSS class for 'complete' status
      } else if (status === "late") {
        return "late-cell"; // Apply your CSS class for 'late' status
      } else if (status === "client to notify") {
        return "client-to-notify"; // Apply your CSS class for 'client to notify' status
      }
      return ""; // No special styling for other statuses
    },
  },
  /*   { field: "dateDeadline", headerName: "Date limite", width: 190 , renderCell: (params) => (
      
      
      <p>{params.value && params.value !== undefined ? params.value.split("T")[0] : "no deadline set"}</p>

    

    ) }, */
    { field: "Voir", headerName: "Details", width: 190 ,    renderCell: (params) => {
      return (
      
          <Link to={"user/clientProfile/" + params.row._id} style={{ textDecoration: "none" }}>
            <BUTTON className="viewButton" size='small'   style={{marginLeft:'15%'}}  variant="outlined">view</BUTTON>
          </Link>
       


          
      );
    }

  },

    
    { field: "Supprimer", headerName: "Delete", width: 190 ,  renderCell: (params) => {
      return (
        <BUTTON variant="outlined" color="error"
        className="deleteButton"
        size='small'
        style={{marginLeft:'3%'}}
        onClick={() => handleClickOpen(params.row)}
        
        
      >Delete</BUTTON>
      );
    }

  },

  ];

  // actionColumn is used here to add the action buttons
 
  return (
    
    <div className="datatable">
      <div className="datatableTitle">
      
        <Link to={"newClient/"+props.rid} className="link" id="new-client">
         Create a new client
        </Link>
      </div>
     
      {showConfirmation && (
        <div className="confirmation-dialog">
          <p>Are you sure you want to delete?</p>
          <button className="confirm-button" onClick={handleConfirm}>
            Confirm
          </button>
          <button className="cancel-button" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      )}

      <React.Fragment>
    
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this client ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            
            Do you really want to delete this client? Warning, this action is irreversible and will result in the deletion of all associated client documents.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

      <DataGrid
      
        
        rows={props.clients}
        columns={columns2}
        getRowId={(row) => row._id}
        localeText={{
 
    toolbarFilters: "Search",
   
  }}
        components={{
    Toolbar: GridToolbarFilterButton ,
  }}

 
      

       
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection

      />
    </div>
  );
};

export default Datatable;