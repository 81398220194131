import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Router , Routes} from "react-router-dom";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Login from './pages/login-client/Login';
import { PostHogProvider} from 'posthog-js/react'

import { MsalProvider, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, loginRequest } from './authConfig'

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}


const root = ReactDOM.createRoot(document.getElementById('root'));
// const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode> 

  {/* <RouterProvider router={router} /> */}
  {/* <MsalProvider instance={msalInstance}> */}
   <PostHogProvider 
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    > 

  <App />

  </PostHogProvider> 
  {/* </MsalProvider> */}

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
