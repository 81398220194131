
import React from 'react';
import { Outlet } from 'react-router-dom'; 
import Sidebar from "../component/sidebar/Sidebar";
import Navbar from "../component/navbar/Navbar";
import { useState, useEffect, useRef } from "react";
import Axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';


const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS


function Layout() {

    const [data, setData] = useState(null);

useEffect(() => {
    Axios ({
       method: "GET",
      withCredentials: true,
        url: `${backEndUrl}/auth/consultantLog`,
 
     
      }).then((res) => {
       setData(res.data);       
      });
 }, []) 

 if (data === undefined || !data) {

    return (
<div style={{marginTop:'20%', marginLeft:'50%' }}>  
      <CircularProgress color="secondary" />
      </div>      
      )

}



    return (
      <div>
       <div className="home">
       <Sidebar 
                   userID={data.id}
            email={data.email}
            emailCount={data.emailCount}
            subscription={data.subscription}
            storageUsed={data.storageUsed}

    />
    
        <div className="homeContainer">
        <Navbar 
               userID={data.id}
            email={data.email}
            emailCount={data.emailCount}
            subscription={data.subscription}
            name={data.name}
          
    />
        <main>
          <Outlet /> {/* This is where nested route components will be rendered */}
        </main>
        </div>
        </div>
      </div>
    );
  }

  export default Layout;