import { useState, useEffect } from "react";
import Axios from "axios";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import TemplateTable from "../../component/templatetable/TemplateTable";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export default function TemplateList() {

    const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

    const [data, setData] = useState();

const id = window.location.pathname.split("/")[2]


    useEffect(() => {
        Axios ({
           method: "GET",
          withCredentials: true,
            url: `${backEndUrl}/auth/consultantLog`,
     
         
          }).then((res) => {
           setData(res.data);       
          });
     }, []) 

    
     

     if (data === undefined) {

        return (
            <div style={{marginTop:'20%', marginLeft:'50%' }}>  
      <CircularProgress color="secondary" />
      </div>  
        )
    }
    
    else {

    return (
        <div>
             <div className="home">
 

    <div className="homeContainer">



    <div className="mt-8 ml-8">
      <div>
        <nav aria-label="Back" className="sm:hidden">
          <a href="#" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon aria-hidden="true" className="-ml-1 mr-1 h-5 w-5 shrink-0 text-gray-400" />
            Back
          </a>
        </nav>
        <nav aria-label="Breadcrumb" className="hidden sm:flex">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  List
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon aria-hidden="true" className="h-5 w-5 shrink-0 text-gray-400" />
                <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  Templates
                </a>
              </div>
            </li>
        
          </ol>
        </nav>
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl/7 mt-2 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Your templates
          </h2>
        </div>
        <div className="mt-4 flex shrink-0 md:ml-4 md:mt-0">
  {/*         <button
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 mr-5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 "
          >
            create new template
          </button> */}
          </div>
          </div>
          </div>

 

     <TemplateTable
              clients={data.docTemplates}
              rid={data.id}
              getRowId={(row) => row.data.docTemplates._id}
   
        /> 
        </div>
        
        </div>
      
        </div>
    )
}
}

