import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Axios from 'axios';


import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {

  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

  const [email, setEmail] = React.useState('');


  const handleSubmit = (event) => {
    event.preventDefault();
        Axios({
      method: "POST",
      data: { email: email },
      withCredentials: true,
      url: `${backEndUrl}/update/resetPasswordRequest`,
    }).then((res) => {
      if (res.data.message && res.data.message.includes('Too many requests')) {
        alert('Trop de tentatives. Veuillez réessayer dans 5 minutes.');
      } else if (res.data !== 'No User Exists') {
        alert('Un e-mail a été envoyé à votre adresse e-mail. Veuillez vérifier votre boîte de réception.');
      } else {
        alert('Aucun utilisateur avec cette adresse e-mail n\'a été trouvé.');
      }
    }).catch((error) => {
      console.error('Error:', error);
      alert('Un e-mail a été envoyé à votre adresse e-mail. Veuillez vérifier votre boîte de réception.');
    });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Your Company"
            src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Mot de passe oublié ?
          </h2>
        </div>
        <div>
          <p className="mt-10 text-center text-sm text-gray-800">
            Entrez votre adresse e-mail ci-dessous pour recevoir un e-mail de réinitialisation du mot de passe.
          </p>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Adresse e-mail
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Envoyer
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Vous avez déjà un compte ?{' '}
            <a href="/" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Connectez-vous
            </a>
          </p>
        </div>
      </div>
  );
}