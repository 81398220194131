import "./Navbar.scss";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { useContext } from "react";

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


import Axios from "axios";
import { useEffect, useState } from "react";





const Navbar = (props) => {

 
  const [open, setOpen] = React.useState(false);
  const [subscription, setSubscription] = React.useState('');
  const [isValid, setIsValid] = useState(false);

  const handleChange = (event) => {
    setSubscription(event.target.value);
    setIsValid(event.target.value !== '')
  };


  const handleClose = () => {
    setOpen(false);
  };

  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS
 


  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  
  

  const askUpgrade = async () => {
    setOpen(true);
  }

  const handleUpgrade = async () => {
  
    if (isValid) {
      try {
        const response = await Axios.post(`${backEndUrl}/users/create-checkout-session`, {
          consultantEmail: props.userID,
          consultantID: props.email,
          subType: subscription
        }, {
          withCredentials: true,
        
        });
    
        if (response.status !== 200) {
          throw new Error('Network response was not ok');
        }
    
        // Handle the response data
        const body = response.data;
        window.location.href = body.url;
      } catch (error) {
        console.error('Error:', error);
      } 
    }
  }

 





  return (
    <div className="navbar">
      <div className="wrapper">

      <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
{props.name}
</div>


      {props.emailCount >= 100 && props.subscription ==='free' ? 
      <div style={{}}> 
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">Limit reached</Typography>
           <p>Attention, your email sending limit has been reached. No automatic emails will be sent. Please upgrade to the Pro version to unlock email sending features.</p>
          </React.Fragment>
        }
      >
     <div style={{ display: 'flex', alignItems: 'center' }}>
          <ErrorOutlineRoundedIcon style={{ color: 'red' }} />
          <p style={{color: 'red', margin: '0 0 0 8px'}}>Email sending limit reached</p>
        </div>
</HtmlTooltip>

 
   
      </div> : null}
      
     
     { props.subscription=== 'free' ?  <span className="inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
        Lite plan
      </span>: <div>  <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
        Business plan
      </span></div>}  
      
         
      
        </div>

        
        <div className="items">
        
       
       
       
          <button
            onClick={askUpgrade}
            className="flex items-center rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            style={{ marginRight: '50px' }}
            disabled={props.subscription !== 'free'}
          >
            UPGRADE
            <ArrowCircleUpIcon aria-hidden="true" className="ml-1 h-5 w-5" />
          </button> 
    

      
        

       
      {/*
          <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div>
          <div className="item">
            <ListOutlinedIcon className="icon" />
          </div> */}
          <div className="item">
           {/*  <img
              src="https://www.theflagstore.ca/store/wp-content/uploads/caflag.jpg"
              alt=""
              className="avatar"
            /> */}
          </div>
        </div>

        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="p-4"
            >
                <DialogTitle id="alert-dialog-title" className="text-lg font-semibold text-gray-900">
                    Upgrade Your Subscription
                </DialogTitle>
                <DialogContent className="p-6">
                    <DialogContentText id="alert-dialog-description" className="text-gray-700">
                        Please select a plan to continue.
                    </DialogContentText>
                    <div className="mt-4">
                        <InputLabel id="demo-simple-select-label" className="block text-sm font-medium text-gray-700">
                            Subscription
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={subscription}
                            onChange={handleChange}
                            className="mt-2 w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                            required
                        >
                            <MenuItem value='monthly'>Monthly</MenuItem>
                            <MenuItem value='yearly'>Yearly</MenuItem>
                        </Select>
                        {subscription === 'monthly' && (
                            <p className="mt-4 text-sm text-gray-600">Monthly subscription: $39 CAN/ month</p>
                        )}
                        {subscription === 'yearly' && (
                            <p className="mt-4 text-sm text-gray-600">Yearly subscription: $399 CAN/ year</p>
                        )}
                    </div>
                </DialogContent>
                <DialogActions className="p-4">
                    <Button onClick={handleClose} >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleUpgrade}
                        autoFocus
                        disabled={!isValid}
                        className="bg-indigo-600 text-white hover:bg-indigo-500"
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
      </div>
  
  );
};

export default Navbar;