// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.login {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://images.pexels.com/photos/417074/pexels-photo-417074.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover; 



}



.loginTitle {
  font-size: 50px;
}

.loginForm {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.loginForm > label {
  margin: 10px 0;
}

.loginInput {
  padding: 10px;
  background-color: white;
  border: none;
  border-radius: 10px;
}

.loginInput:focus {
  outline: none;
}

.loginButton {
  margin-top: 20px;
  cursor: pointer;
  background-color: lightcoral;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 10px;
  text-align: center;
}

.loginButton:disabled{
  cursor: not-allowed;
  background-color: rgb(232, 145, 145);
}

.loginRegisterButton {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: teal;
  cursor: pointer;
  padding: 10px;
  border: none;
  color: white;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,0BAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB;;;;wHAIsH;EACtH,sBAAsB;;;;AAIxB;;;;AAIA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,4BAA4B;EAC5B,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,sBAAsB;EACtB,eAAe;EACf,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":["\n.login {\n  height: calc(100vh - 50px);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background: linear-gradient(\n      rgba(255, 255, 255, 0.5),\n      rgba(255, 255, 255, 0.5)\n    ),\n    url(\"https://images.pexels.com/photos/417074/pexels-photo-417074.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1\");\n  background-size: cover; \n\n\n\n}\n\n\n\n.loginTitle {\n  font-size: 50px;\n}\n\n.loginForm {\n  margin-top: 20px;\n  display: flex;\n  flex-direction: column;\n}\n\n.loginForm > label {\n  margin: 10px 0;\n}\n\n.loginInput {\n  padding: 10px;\n  background-color: white;\n  border: none;\n  border-radius: 10px;\n}\n\n.loginInput:focus {\n  outline: none;\n}\n\n.loginButton {\n  margin-top: 20px;\n  cursor: pointer;\n  background-color: lightcoral;\n  color: white;\n  padding: 10px;\n  border: none;\n  border-radius: 10px;\n  text-align: center;\n}\n\n.loginButton:disabled{\n  cursor: not-allowed;\n  background-color: rgb(232, 145, 145);\n}\n\n.loginRegisterButton {\n  position: absolute;\n  top: 60px;\n  right: 20px;\n  background-color: teal;\n  cursor: pointer;\n  padding: 10px;\n  border: none;\n  color: white;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
