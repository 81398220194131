import React from "react";
import { useState, useEffect } from "react";
import "./Notification.scss";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Axios from "axios";

export default function Notifications(props) {

  const [open, setOpen] = React.useState(true);
  
  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

  const closeNotif = async () => {

    try {
  
      await Axios ({
          method: "PUT",
          withCredentials: true,
          data: {
            notif_id : props.rid

          },
          
           url : `${backEndUrl}/updateClient/notifClosed`  ,
        
      })
  
    } catch (error) {
      
    }


  }

    return (

  <div className="header" style={props.closed ? {} : {  }}>
    {props.closed === false ? (
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
                closeNotif();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 1 }}
          severity={props.type === "complete" ? "success" : "error"}
        >
          {props.message}
        </Alert>
      </Collapse>
    ) : null}
  </div>


    );
  }