// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email2 {
  display: flex;
}

.email-section {
  display: flex;
  flex-direction: column;
  flex: 10 1;
  margin-left: 4%;
}

.titleForEmail {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 2%;
  margin-top: 2%;
  text-align: center;
}

.emailTemplate {
  height: 100%;
  border-radius: 10px;
  padding: 2%;
  padding-left: 45px;
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  flex: 10 1;
  margin-right: 17px;
}

.deadlineContainer {
  margin-top: 4%;
  margin-left: 33%;
  margin-bottom: 4%;
}

.clientContainer {
  margin-left: 2%;
  margin-bottom: 4%;
  margin-top: 3%;
}

.buttonContainer {
  display: flex;
  margin-left: 2%;
  gap: 78%;
}

.monster {
  margin-top: 1%;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/User.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,UAAA;EACA,eAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AACJ;;AAGA;EACI,YAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EAEA,sDAAA;EACA,UAAA;EACA,kBAAA;AAAJ;;AAGA;EAKI,cAAA;EACA,gBAAA;EACA,iBAAA;AAJJ;;AAQA;EAEI,eAAA;EACA,iBAAA;EACA,cAAA;AANJ;;AAWA;EACI,aAAA;EACA,eAAA;EACA,QAAA;AARJ;;AAWA;EACI,cAAA;AARJ","sourcesContent":[".email2{\n    display:flex;\n}\n\n.email-section{\n    display: flex;\n    flex-direction: column;\n    flex:10;\n    margin-left: 4%;\n}\n\n.titleForEmail{\n    font-size: 1rem;\n    font-weight: 600;\n    margin-bottom: 2%;\n    margin-top: 2%;\n    text-align: center;\n}\n\n\n.emailTemplate{\n    height: 100%;\n    border-radius: 10px;    \n    padding: 2%;\n    padding-left: 45px;\n    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);\n    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);\n    flex:10;\n    margin-right: 17px;\n}\n\n.deadlineContainer {\n   // background-color: blue;\n    //justify-content: center;\n    //align-items: center;\n \n    margin-top: 4%;\n    margin-left: 33%;\n    margin-bottom: 4%;\n  \n}\n\n.clientContainer{\n  \n    margin-left: 2%;\n    margin-bottom: 4%;\n    margin-top:3%;\n\n  \n}\n\n.buttonContainer {\n    display: flex;\n    margin-left: 2%;\n    gap:78%;\n}\n\n.monster{\n    margin-top:1%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
