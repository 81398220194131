// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reasonMap {
  margin-top: 2%;
}

.reasonList {
  margin-top: 1%;
  margin-left: 4%;
}`, "",{"version":3,"sources":["webpack://./src/component/wrongDocs/wrongDocs.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAGA;EACI,cAAA;EACA,eAAA;AAAJ","sourcesContent":[".reasonMap{\n    margin-top: 2%;\n    \n}\n\n.reasonList{\n    margin-top:1%;\n    margin-left: 4%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
