/* 
export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID, // Replace with your registered Azure app client ID
      authority: 'https://login.microsoftonline.com/common', 
      clientSecret: process.env.REACT_APP_MICROSOFT_CLIENT_SECRET,
     
      redirectUri: 'http://localhost:3000/auth/callback', // Your redirect URI
    },
    cache: {
      cacheLocation: 'localStorage', // Store the tokens in local storage
      storeAuthStateInCookie: true,  // Set to true for IE11/Edge
    },
  };
  
  export const loginRequest = {
    scopes: ['openid', 'profile', 'User.Read', 'Mail.Send', 'offline_access'], // Add scopes required for sending emails
  };
  

   */