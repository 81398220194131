import "./docReview.scss";
import { Grid } from "@mui/material";
import Axios from "axios";
import { useState, useEffect } from "react";
import {  useParams } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';




const DocReview = (props) => {

  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  const handleCloseBackDrop = () => {
    setOpenBackDrop(false);
  };
  const handleOpenBackDrop = () => {
    setOpenBackDrop(true);
  };




  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS


  const { id } = useParams();
  const [data, setData] = useState();

  const [fileName, setFileName] = useState("");
  const [link, setLink] = useState("");

  const onChangeFile = (e) => {
    setFileName(e.target.files[0]);
          };

  const handleDownloader = (key) => {
    
    window.location.href = `${backEndUrl}/updateClient/download/${key}`; // Change this URL according to your backend setup
  };



const changeonclick = (e) => {

// storage Used should not be more than 250 mb

  if (props.storageUsed > 262144000 ) {
    alert("Limite de stockage, veuillez contacter votre consultant pour plus d'informations");
    return;
  }

  if (fileName.size > 8388608) {
    alert("La taille du fichier ne doit pas dépasser 8 MB.");
    return;
}

  e.preventDefault();

  handleOpenBackDrop();

  const formData = new FormData();


  formData.append("doc_upload", fileName);
 

  
    formData.append("doc_id" , props.rid);
    formData.append("client_id", id);
    formData.append("clientName", props.clientName);
    formData.append("storageUsed", props.storageUsed);



  

  Axios.put(`${backEndUrl}/updateClient/uploadAS3` , formData, {
      headers: {
          "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
  }).then((res) => {
    handleCloseBackDrop();
  window.location.reload();
    setLink(res.data.path);
  
     
  }
  );
}

  
 

    
return (
  <div className="boxWraper">

      <Backdrop
          sx={{ color: '#fff', zIndex: 1301, position: 'fixed' }}
          open={openBackDrop}
      >
          <CircularProgress color="inherit" />
      </Backdrop>

      <div className="box">
          <Grid container spacing={2}>
              <Grid item xs={4}>
                  <h3>{props.name}</h3>
              </Grid>

              <Grid item xs={3}>
                  {props.consultantAttachment !== "none" ? (
                      <div>
                          <Button size='small' onClick={() => handleDownloader(props.consultantAttachment)} variant="outlined">
                              {props.language === 'french' ? 'Voir document joint' : 'View Attached Document'}
                          </Button>
                      </div>
                  ) : null}
              </Grid>

              <Grid item xs={4}>
                  <p>
                      <span style={{ marginLeft: '55%' }} className={props.status === 'uploaded' ? 'uploaded-status' : 'pending-status'}>
                          {props.language === 'french' ? props.status === 'uploaded' ? 'Téléversé' : 'En attente' : props.status}
                      </span>
                  </p>
              </Grid>

              <Grid item xs={8}>
                  {props.description}
              </Grid>

              <Grid item xs={4}>
                  <form id="uploadForm" onSubmit={changeonclick} encType="multipart/form-data">
                      {props.status === "not uploaded" ? (
                          <label htmlFor="file">{props.language === 'french' ? 'Téléverser le fichier' : 'Upload file'}</label>
                      ) : null}

                      {props.status === "not uploaded" ? (
                          <input style={{ marginLeft: '10px' }} type="file" fileName="doc_upload" name={props.rid} onChange={onChangeFile} />
                      ) : null}

                      {props.status === "not uploaded" ? (
                          <div>
                              <Button style={{ marginTop: '20px', marginLeft: '20px' }} size="small" color="error" variant="contained" onClick={handleClickOpen} endIcon={<SendIcon />}>
                                  {props.language === 'french' ? 'Soumettre' : 'Submit'}
                              </Button>
                              <React.Fragment>
                                  <Dialog
                                      open={open}
                                      onClose={handleClose}
                                      aria-labelledby="alert-dialog-title"
                                      aria-describedby="alert-dialog-description"
                                  >
                                      <DialogTitle id="alert-dialog-title">
                                          {props.language === 'french' ? "Téléverser ce document ?" : "Upload this document?"}
                                      </DialogTitle>
                                      <DialogContent>
                                          <DialogContentText id="alert-dialog-description">
                                              <span>{props.language === 'french' ? `Êtes-vous sûr de vouloir transmettre le document '${fileName.name}' ?` : `Are you sure you want to transmit the following '${fileName.name}'?`}</span>
                                              <br /><br />
                                              <span>{props.language === 'french' ? "Assurez-vous qu'il s'agit du bon document, qu'il est complet et qu'il répond aux exigences." : "Make sure this is the right document, ensure it's complete and that it meets the requirements."}</span>
                                              <br /><br />
                                              <span className="boldText">{props.language === 'french' ? "AVERTISSEMENT : Une fois que vous avez soumis ce document, vous ne pourrez pas en téléverser un autre après avoir cliqué sur 'Soumettre'." : "WARNING: Once you submit this document, you won’t be able to upload another one after clicking 'Submit'."}</span>
                                          </DialogContentText>
                                      </DialogContent>
                                      <DialogActions>
                                          <Button onClick={handleClose} size="small" variant='outlined'>{props.language === 'french' ? 'Annuler' : 'Cancel'}</Button>
                                          <Button
                                              onClick={changeonclick}
                                              variant="contained"
                                              autoFocus
                                              size="small"
                                          >
                                              {props.language === 'french' ? 'Soumettre' : 'Submit'}
                                          </Button>
                                      </DialogActions>
                                  </Dialog>
                              </React.Fragment>
                          </div>
                      ) : null}

                      {props.status === "uploaded" ? (
                          <div>
                              <div>
                                  <Button style={{ marginLeft: '20px', marginTop: '15px' }} onClick={async () => {
                                      try {
                                          const response = await fetch(`${backEndUrl}/updateClient/viewDoc`, {
                                              method: 'POST',
                                              headers: {
                                                  'Content-Type': 'application/json',
                                              },
                                              credentials: 'include',
                                              body: JSON.stringify({ key: props.path }),
                                          });
                                          const data = await response.json();
                                          const newWindow = window.open();
                                          newWindow.location.href = data;
                                      } catch (error) {
                                          console.error('Error fetching pre-signed URL: ', error);
                                      }
                                  }} variant="outlined">
                                      {props.language === 'french' ? 'Voir mon document' : 'View my document'}
                                  </Button>
                              </div>
                          </div>
                      ) : null}
                  </form>
              </Grid>
          </Grid>
      </div>
  </div>
);
}

export default DocReview;