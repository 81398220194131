// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addNewBtnTemplate {
  margin-top: 5%;
}

.templateExplaination {
  margin-top: 3%;
  margin-left: 5%;
  font-size: 13px;
  line-height: 1.5;
}

.submitTemplate {
  margin-top: 3%;
}

.templateForm {
  margin-left: 4%;
}`, "",{"version":3,"sources":["webpack://./src/pages/docTemplates/DocTemplate.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAGA;EACI,cAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;AAAJ;;AAGA;EACE,cAAA;AAAF;;AAGA;EACI,eAAA;AAAJ","sourcesContent":[".addNewBtnTemplate{\n    margin-top: 5%;\n\n}\n\n.templateExplaination{\n    margin-top: 3%;\n    margin-left: 5%;\n    font-size: 13px;\n    line-height: 1.5;\n}\n\n.submitTemplate{\n  margin-top: 3%;\n}\n\n.templateForm{\n    margin-left: 4%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
